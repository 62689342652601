@use '@strategies/tokens/layout';

.Process {

    .video-wrap {
        position: relative;
        height: 50vw;
        width: 100%;
        overflow: hidden;
        z-index: 1;
    
        @include respond-to (desktop) {
            padding-right: $dims_padding-large ;
        padding-left: $dims_padding-large ;
        }
        iframe {
            display: block;
            // position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        video {
            width: 100%;
        }
       
    }

    

}
